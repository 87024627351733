<template>
  <b-card
    no-body
    class="p-3"
  >
    <b-row align-h="center">
      <b-col
        cols="6"
      >
        <validation-observer ref="formRef">
          <b-form @submit.prevent>
            <b-row>
              <b-col sm="12">
                <b-list-group class="mb-2">
                  <b-list-group-item class="d-flex align-items-center justify-content-between">
                    <div class="h4 font-weight-bold">No Cash Advance </div>
                    <div class="h4 font-weight-bold"> {{previewData.code}} </div>
                  </b-list-group-item>
                  <b-list-group-item class="d-flex align-items-center justify-content-between">
                    <div class="h4 font-weight-bold">Employee </div>
                    <div class="h4 font-weight-bold"> {{previewData.employee.label}} </div>
                  </b-list-group-item>
                  <b-list-group-item class="d-flex align-items-center justify-content-between">
                    <div class="h4 font-weight-bold">Cash Advance Amount </div>
                    <div class="h4 font-weight-bold"> {{ formatCurrency(parseFloat(previewData.amount)) }} </div>
                  </b-list-group-item>
                  <b-list-group-item class="d-flex align-items-center justify-content-between">
                    <div class="h4 font-weight-bold">Balance Amount </div>
                    <div class="h4 font-weight-bold"> {{ formatCurrency(parseFloat(previewData.balance_amount)) }} </div>
                  </b-list-group-item>
                </b-list-group>
              </b-col>

              <!-- Refund To -->
              <b-col cols="6">
                <b-form-group
                  :label="$t(`apps.transactionsCashAdvance.singular.refundTo`)"
                  label-for="account-id"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="account-id"
                    rules="required"
                  >
                    <v-select
                      v-if="companyActive.plan_id > 2"
                      id="account-id"
                      class="select-size-sm"
                      ref="firstFocusSelectInput"
                      v-model="form.cashbankId"
                      :options="LOV.cashbankCodeAccounts"
                      :reduce="field => field.id"
                      label="label"
                      :state="errors.length > 0 ? false:null"
                      :disabled="actions.isPreview"
                    />
                    <v-select
                      v-else
                      id="account-id"
                      class="select-size-sm"
                      ref="firstFocusSelectInput"
                      v-model="form.accountId"
                      :options="LOV.cashBankAccounts"
                      :reduce="field => field.id"
                      label="label"
                      :state="errors.length > 0 ? false:null"
                      :disabled="actions.isPreview"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <!-- Date Field -->
              <b-col cols="6">
                <b-form-group
                  :label="$t('apps.transactionsCashAdvance.singular.date')"
                  label-for="date"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="date"
                    rules="required"
                  >
                    <flat-pickr
                      id="date"
                      :config="dateFormat"
                      v-model="form.date"
                      :state="errors.length > 0 ? false:null"
                      :disabled="actions.isPreview"
                      class="form-control form-control-sm"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <!-- Refund Amount -->
              <b-col cols="12">
                <b-form-group
                  :label="$t('apps.transactionsCashbank.singular.amount')"
                  label-for="code"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="amount"
                    :rules="`required|max_value:${calculationAmount.initialBalanceAmount}`"
                  >
                    <cleave
                      v-model.number="form.refundAmount"
                      :state="errors.length > 0 ? false:null"
                      class="form-control text-right form-control-sm"
                      :options="numeric"
                      :disabled="actions.isPreview"
                      placeholder="10,000"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>

            <!-- Description Field -->
            <b-form-group
              :label="$t('apps.transactionsCashAdvance.singular.notes')"
              label-for="notes"
            >
              <b-form-textarea
                id="notes"
                v-model="form.description"
                :disabled="actions.isPreview"
              />
            </b-form-group>

            <div class="mt-3 d-flex flex-sm-column flex-md-row justify-content-between">
              <b-button
                class="mb-sm-1 mb-md-0"
                :variant="actions.isPreview ? 'outline-secondary' : 'outline-primary'"
                :to="{ name: 'apps-transactions-cash-advance-list'}"
              >
                <feather-icon
                  icon="ArrowLeftIcon"
                />
                {{ $t('globalActions.backToList') }}
              </b-button>

              <b-button
                v-if="!actions.isPreview"
                variant="primary"
                type="submit"
                @click="handleSubmit"
              >
                <feather-icon
                  icon="SaveIcon"
                />
                {{ actions.isEditMode ? $t('globalActions.update') : $t('globalActions.create') }}
              </b-button>


              <b-button
                v-if="actions.isPreview"
                variant="outline-primary"
              >
                <feather-icon
                  icon="EditIcon"
                />
                {{ $t('apps.transactionsCashAdvance.actions.realisation') }}
              </b-button>

            </div>
          </b-form>
        </validation-observer>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormGroup,
  // BFormInput,
  BListGroup,
  BListGroupItem,
  BForm,
  BFormTextarea,
  BButton
} from 'bootstrap-vue'
import Cleave from 'vue-cleave-component'
import vSelect from 'vue-select'
import {ref, onMounted} from '@vue/composition-api'
import {ValidationProvider, ValidationObserver} from 'vee-validate'
import flatPickr from 'vue-flatpickr-component'
import {required} from '@validations'
import {formatCurrency, formatDatepicker} from '@/utils/formatter'

import useFormResource from '@/comp-functions/useFormResource'
import storestate from '@/store/index'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    // BFormInput,
    BListGroup,
    BListGroupItem,
    BForm,
    BFormTextarea,
    BButton,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    flatPickr,
    Cleave
  },
  setup () {
    const {
      paramsId,
      formRef,
      actions,
      changeToEdit,
      get,
      customStore,
      destroy
    } = useFormResource({
      url: 'transaction/cash-advance',
      localeContextPath: 'apps.transactionsCashAdvance.singular.cashAdvance',
      redirectPathName: 'apps-transactions-cash-advance-list'
    })

    const companyActive = storestate.state.user.currentCompany

    const previewData = ref({})
    const calculationAmount = ref({
      initialBalanceAmount: 0,
      finalBalanceAmount: 0
    })

    const form = ref({
      accountId: '',
      cashbankId: '',
      date: formatDatepicker(Date.now()),
      description: '',
      refundAmount: 0
    })

    const LOV = ref({
      types: [
        { code: 'CAOP', name: 'CASH ADVANCE' },
        { code: 'CAPR', name: 'PERSONAL' }
      ],
      realizationTypes: [
        { code: 'PICKUP', name: 'PICKUP' },
        { code: 'OUTGOING', name: 'OUTGOING' },
        { code: 'TRANSIT', name: 'TRANSIT' },
        { code: 'INCOMING', name: 'INCOMING' },
        { code: 'DELIVERY', name: 'DELIVERY' }
      ],
      employees: [],
      accounts: [],
      cashbankCodeAccounts: []
    })

    const numeric = ref({
      numeral: true,
      numeralThousandsGroupStyle: 'thousand',
      numeralPositiveOnly: true,
    })

    const dateFormat = ref({ dateFormat: 'd/m/Y', allowInput: true })

    const getEmployees = async () => {
      LOV.value.employees = await get({ url: 'master/contact?type={CTKR}'})
    }

    const getAccounts = async () => {
      LOV.value.accounts = await get({ url: 'master/accounts'})
    }

    const getCashbankCode = async () => {
      LOV.value.cashbankCodeAccounts = await get({ url: 'value/mapping-cashbank-per-users'})
    }


    onMounted(() => {
      getAccounts()
      getEmployees()

      if (companyActive.plan_id > 2) {
        getCashbankCode()
      }
    })

    return {
      previewData,
      companyActive,
      calculationAmount,
      form,
      numeric,
      paramsId,
      formRef,
      get,
      customStore,
      destroy,
      actions,
      changeToEdit,
      LOV,
      required,
      dateFormat,
      formatCurrency
    }
  },
  mounted () {
    if (this.paramsId) {
      this.actions.isPreview = true
      this.getDataPreview()
    }
  },
  methods: {
    async getDataPreview () {
      const data = await this.get({
        url: `transaction/cash-advance/${this.paramsId}`
      })

      this.previewData = data
      this.calculationAmount.initialBalanceAmount = parseInt(data.balance_amount ?? 0)
      this.calculationAmount.finalBalanceAmount = parseInt(data.balance_amount ?? 0)
      this.actions.isPreview = false
      this.actions.isEditMode = true
    },
    handleDelete () {
      this.destroy(this.$swal)
    },
    handleSubmit () {
      this.customStore({
        $swal: this.$swal,
        data: this.form,
        url: `transaction/cash-advance/${this.paramsId}/refund`,
        text: {
          swalTitle: `Create ${this.$t('apps.transactionsCashAdvance.singular.cashAdvance')} ${this.$t('apps.transactionsCashAdvance.actions.refund')}  ?`,
          swalText: `${this.$t('info.notAbleRecover')}.`,
          successText: `${this.$t('feedback.createdNew')} ${this.$t('apps.transactionsCashAdvance.singular.cashAdvance')} ${this.$t('apps.transactionsCashAdvance.actions.refund')}.`
        }
      })
    }
  }
}
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
  @import '@core/scss/vue/libs/vue-sweetalert.scss';
  @import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
